
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  computed,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import moment from "moment";
import BuSelector from "@/views/Payment/components/BuSelector.vue";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
import DealerTable from "./dealerTable.vue";
import FlTable from "./flTable.vue";
import {
  fetchDealerConfirm,
  fetchDealerReturn,
  fetchFinancialConfirm,
  fetchFinancialReturn,
  fetchCheckStatusList,
  dealerVerifyReturn,
  financialVerifyReturn,
  dealerVerifyConfirm,
  financialVerifyConfirm,
  dealerAddComments1,
  dealerAddComments2,
  getCheckBeforeConfirm,
  verifyFinancialCheckBeforeConfirm,
  financialAddComments1,
  financialAddComments2
} from "@/API/checking/spotChecking";
import { message } from "ant-design-vue";
import ReturnConfirmModal from "../components/returnModalConfirm.vue";
import useEventbus from "@/hooks/checking/spotChecking/useEventbus";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const { customEmit, customOff } = useEventbus();
    const dealerRef = ref();
    const flRef = ref();
    const query = reactive({
      entity: undefined,
      bu: undefined,
      priority: undefined,
      submissionPeriodEnd: "",
      submissionPeriodStart: "",
    });
    const period = ref<any[]>([]);
    const initPeriod = () => {
      query.submissionPeriodStart = moment()
        .month(moment().month() - 1)
        .startOf("month")
        .format("YYYY-MM-DD");
      query.submissionPeriodEnd = moment()
        .month(moment().month() + 1)
        .endOf("month")
        .format("YYYY-MM-DD");
      period.value = [query.submissionPeriodStart, query.submissionPeriodEnd];
    };
    const handlePeriodChange = (period: any) => {
      query.submissionPeriodStart = moment(period[0]).format("YYYY-MM-DD");
      query.submissionPeriodEnd = moment(period[1]).format("YYYY-MM-DD");
    };
    const search = () => {
      const dealer = dealerRef.value.search();
      const fl = flRef.value.search();
      Promise.all([dealer, fl]).then((res) => {
        customEmit("on-count-change", res[0] + res[1]);
      });
    };
    const init = () => {
      // getStatusList();
      initPeriod();
      search();
    };
    onMounted(() => {
      init();
    });

    const reset = () => {
      query.entity = undefined;
      query.bu = undefined;
      query.priority = undefined;
      initPeriod();
      dealerRef.value.search();
      flRef.value.search();
    };
    let dealerIds: any[] = [];
    let flIds: string[] = [];
    const handleDealerChange = (ids: any) => {
      dealerIds = ids;
    };
    const handleFlChange = (ids: any) => {
      flIds = ids;
    };
    const returnModalVisible = ref(false);
    const returnCommentModalVisible = ref(false);

    const handleReturn = async () => {
      if (dealerIds.length === 0 && flIds.length === 0) {
        message.warning("Please select the data first.");
      } else {
        if (dealerIds.length > 0) {
          const result = await dealerVerifyReturn(dealerIds);
          if (!result.data) {
            // message.warning(result.message);
          } else {
            returnModalVisible.value = true;
          }
        }
        if (flIds.length > 0) {
          const result = await financialVerifyReturn(flIds)
          if (!result.data) {
            // message.warning(result.message);
          } else {
            returnModalVisible.value = true;
          }
        }
      }
    };
    const dealerData = ref([])
    const dealerDataId = ref([])
    const getDealerData = (data: any)=>{
      dealerData.value = data.data
      const dealerIdArr=ref<any>([])
      dealerData.value.forEach((i: any,ind: any)=>{
        dealerIdArr.value.push(i.id)
      })
      dealerDataId.value = dealerIdArr.value
    }
    const handleReturnConfirm = async (comment: any) => {
      let returnDealer = false
      let returnFinancial = false
      if (dealerIds.length === 0 && flIds.length === 0) {
        message.warning("Please select the data first.");
        return;
      }
      if (comment.length === 0) {
        message.error('Reason is required');
        return;
      }
      if (dealerIds.length > 0) {
        const result = await dealerVerifyConfirm(dealerIds);
        if (!result.data) {
          // message.warning(result.message);
          return;
        }
        returnDealer = true;
      }
      if (flIds.length > 0) {
        const result = await financialVerifyConfirm(flIds);
        if (!result.data) {
          // message.warning(result.message);
          return;
        }
        returnFinancial = true;
      }

      await Promise.all([
        returnDealer ? dealerAddComments2(comment, dealerIds) : Promise.resolve(null),
        returnFinancial ? financialAddComments2(comment, flIds) : Promise.resolve(null)
      ]);

      await Promise.all([
        returnDealer ? fetchDealerReturn(dealerIds): Promise.resolve(null),
        returnFinancial ? fetchFinancialReturn(flIds): Promise.resolve(null)
      ]);
      message.success("Return successfully!");
      if (returnDealer) {
        dealerRef.value.search();
      }
      if (returnFinancial) {
        flRef.value.search();
      }
      returnCommentModalVisible.value = false;
    };
    const commentModalVisible = ref(false);
    const comment = ref('');

    let resolveAddComment: ((value: unknown) => void) | null  = null
    let rejectAddComment: ((reson?: any) => void) | null  = null

    // const handleCloseReturnVisible = ()=>{
    //   returnVisible.value=false
    //   pageComments.value=''
    // }


    const handleConfirm = async () => {
      let confirmDealer = false
      let confirmFinancial = false
      if (dealerIds.length === 0 && flIds.length === 0) {
        message.warning("Please select the data first.");
        return;
      }

      if (dealerIds.length > 0) {
        const result = await dealerVerifyConfirm(dealerIds);
        if (!result.data) {
          // message.warning(result.message);
          return;
        }
        confirmDealer = true;
      }
      if (flIds.length > 0) {
        const result = await financialVerifyConfirm(flIds);
        if (!result.data) {
          // message.warning(result.message);
          return;
        }
        confirmFinancial = true;
      }
      const [dealerAddComment, financialAddComment] = await Promise.all([
        confirmDealer ? getCheckBeforeConfirm({
          ids: dealerIds
        }) : Promise.resolve(false),
        confirmFinancial ? verifyFinancialCheckBeforeConfirm(flIds) : Promise.resolve(false)
      ])

      await (new Promise((resolve, reject) => {
        if (dealerAddComment || financialAddComment) {
          comment.value = ''
          commentModalVisible.value = true
          resolveAddComment = async () => {
            if (comment.value.length === 0) {
              message.error('Reason is required')
              return
            }
            await Promise.all([
              dealerAddComment ? dealerAddComments1(comment.value, dealerIds) : Promise.resolve(null),
              financialAddComment ? financialAddComments1(comment.value, flIds) : Promise.resolve(null)
            ])
            resolve(null)
          }
          rejectAddComment = reject
        } else {
          resolve(null)
        }
      })).finally(() => {
        commentModalVisible.value = false
      })

      await Promise.all([
        confirmDealer ? fetchDealerConfirm(dealerIds): Promise.resolve(null),
        confirmFinancial ? fetchFinancialConfirm(flIds): Promise.resolve(null)
      ]);
      message.success("Confirm successfully!")
      if (confirmDealer) {
        dealerRef.value.search()
      }
      if (confirmFinancial) {
        flRef.value.search()
      }
    }

    return {
      comment,
      commentModalVisible,
      resolveAddComment,
      rejectAddComment,
      dealerDataId,
      getDealerData,
      dealerData,
      search,
      reset,
      dealerRef,
      flRef,
      period,
      handlePeriodChange,
      handleConfirm,
      handleReturn,
      handleReturnConfirm,
      returnModalVisible,
      returnCommentModalVisible,
      handleDealerChange,
      handleFlChange,
      priorityList: [
        {
          name: "High",
          value: "1",
        },
        {
          name: "Normal",
          value: "2",
        },
      ],
      query,
      ...toRefs(query),
    };
  },
  components: {
    BuSelector,
    EntitySelector,
    DealerTable,
    FlTable,
    ReturnConfirmModal,
  },
});
