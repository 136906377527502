
import { defineComponent, ref, computed, reactive, toRaw } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { FileDoneOutlined, FileSearchOutlined } from "@ant-design/icons-vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import useFormatDate from "@/utils/payment/useFormatDate";
import { getFinancialList, getFinancialLoglist } from "@/API/checking/spotChecking";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { toThousandsAndTwoDecimal } from "@/utils/payment/numberFormat";
import LogModal from "./logModal.vue";
import { message } from "ant-design-vue";
import downloadFile from "@/utils/payment/downloadFile";
type Key = ColumnProps["key"];
export default defineComponent({
  name: "App",
  props: {
    query: {
      type: Object,
    },
  },
  setup(props, context) {
    const column = ref([
      {
        title: "Payout Code",
        slots: { customRender: "payoutCode" },
        width: 230,
      },
      {
        title: "Program No.",
        dataIndex: "programNo",
        width: 100,
        align:'right'
      },
      {
        title: "Total VIN No. / Amount",
        width: 220,
        slots: { customRender: "totalNo" },
      },
      {
        title: "Spot Checked Passed VIN No. / Amount ",
        width: 300,
        slots: { customRender: "spotCheckedNo" },
        
      },
      {
        title: "Controlling Check Passed VIN No. / Amount ",
        width: 300,
        slots: { customRender: "controllingCheckedNo" },
      },
      {
        title: "Time Line From",
        dataIndex: "timeLineFrom",
        width: 140,
        customRender: useFormatDate("YYYY/MM/DD"),
        align: "left",
      },
      {
        title: "To",
        dataIndex: "timeLineTo",
        customRender: useFormatDate("YYYY/MM/DD"),
        width: 140,
        align: "left",
      },
      { title: "Priority", dataIndex: "priority", width: 80 },
      {
        title: "Operation",
        slots: { customRender: "operation" },
        width: 100,
        fixed: "right",
      },
      {
        title: "Controlling Confirm",
        width: 180,
        dataIndex: "controllingConfirm",
        fixed: "right",
      },
      {
        title: "Log",
        width: 60,
        slots: { customRender: "log" },
        fixed: "right",
      },
    ]);
    const tableSelection = reactive<{
      selectedRowKeys: Key[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: string[]) => {
        tableSelection.selectedRowKeys = selectedRowKeys;
        context.emit("on-select-change", selectedRowKeys);
      },
      selectedRowKeys: [],
    });
    const dataRes = ref();
    const data = computed(() => {
      return dataRes.value?.data;
    });
    const search = () => {
      const _query = toRaw(props.query);
      if (_query) {
        _query.entity = _query.entity === undefined ? null : _query.entity;
        _query.bu = _query.bu === undefined ? null : _query.bu;
        _query.priority =
          _query.priority === undefined ? null : _query.priority;
      }
      // getFinancialList(props.query).then((res) => {
      //   dataRes.value = res;
      // });
      return new Promise((resolve) => {
        getFinancialList(_query).then((res) => {
          dataRes.value = res;
          resolve(res.data ? res.data.length : 0);
        });
      });
    };
    const router = useRouter();
    const { commit } = useStore();
    const handleGoto = (record: any) => {
      commit("spotChecking/updateSpotCheckConfirmPackageFl", {
        ...record,
        type: 'confirm'
      });
      router.push({ path: "/spotCheckConfirmProgramFl" });
    };
    const logModalVisible = ref(false);
    const logData = ref([]);
    const handleLog = (record: any) => {
      logModalVisible.value = true;
      getFinancialLoglist(record.id).then((res: any) => {
        logData.value = res.data
        logModalVisible.value = true;
      });
    };
    const handleExport = (record: any) => {
      const params = {
        url: `/claimapi/spotCheckConfirm/fl/exportExcel?payoutCodeId=${record.id}`,
        method: "post",
      };
      downloadFile(params).catch((res) => {
        if (res.message) {
          message.warning(res.message);
        } else {
          message.warning(res);
        }
      });
    }
    return {
      column,
      data,
      search,
      handleGoto,
      tableSelection,
      isPagination: false,
      toThousandsAndTwoDecimal,
      logModalVisible,
      logData,
      handleLog,
      handleExport
    };
  },
  components: {
    TableWrapper,
    FileDoneOutlined,
    FileSearchOutlined,
    LogModal
  },
});
