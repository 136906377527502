
import { computed, defineComponent, ref, toRaw, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { FileDoneOutlined, FileSearchOutlined } from "@ant-design/icons-vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import { getDealerlist, fetchDealerLog } from "@/API/checking/spotChecking";
import useFormatDate from "@/utils/payment/useFormatDate";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { toThousandsAndTwoDecimal } from "@/utils/payment/numberFormat";
import downloadFile from "@/utils/payment/downloadFile";
import LogModal from "./logModal.vue";
import { message } from "ant-design-vue";
type Key = ColumnProps["key"];
export default defineComponent({
  name: "App",
  props: {
    query: {
      type: Object,
    },
  },
  setup(props, context) {
    const column = ref([
      {
        title: "Package Code",
        width: 180,
        slots: { customRender: "packageCode" },
      },
      {
        title: "Submit Program No. / Amount",
        width: 230,
        slots: { customRender: "submitProgramNo" },
      },
      {
        title: "Spot Checked No. / Amount ",
        width: 230,
        slots: { customRender: "spotCheckedNo" },
      },
      {
        title: "Controlling Check No. / Amount ",
        width: 280,
        slots: { customRender: "controllingCheckedNo" },
      },
      {
        title: "Time Line From",
        dataIndex: "timeLineFrom",
        customRender: useFormatDate("YYYY/MM/DD"),
        width: 100,
        align: "left",
      },
      {
        title: "To",
        dataIndex: "timeLineTo",
        width: 100,
        customRender: useFormatDate("YYYY/MM/DD"),
        align: "left",
      },
      { title: "Priority", dataIndex: "priority" ,width:80},
      {
        title: "Operation",
        slots: { customRender: "operation" },
        width: 100,
        fixed: "right",
      },
      {
        title: "Controlling Confirm",
        width: 180,
        dataIndex: "controllingConfirm",
        fixed: "right",
      },
      {
        title: "Log",
        width: 60,
        slots: { customRender: "log" },
        fixed: "right",
      },
    ]);
    const tableSelection = reactive<{
      selectedRowKeys: Key[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: string[]) => {
        tableSelection.selectedRowKeys = selectedRowKeys;
        context.emit("on-select-change", selectedRowKeys);
      },
      selectedRowKeys: [],
    });
    const dataRes = ref();
    const data = computed(() => {
      return dataRes.value?.data;
    });
    const search = () => {
      const _query = toRaw(props.query);
      if (_query) {
        _query.entity = _query.entity === undefined ? null : _query.entity;
        _query.bu = _query.bu === undefined ? null : _query.bu;
        _query.priority =
          _query.priority === undefined ? null : _query.priority;
        // _query.submissionPeriodStart = null
        // _query.submissionPeriodEnd = null
      }
      return new Promise((resolve) => {
        getDealerlist(_query).then((res) => {
          context.emit('on-getdata',res)
          dataRes.value = res;
          resolve(res.data ? res.data.length : 0);
        });
      });
    };
    const router = useRouter();
    const { commit, state } = useStore();
    const handleGoTo = (record: any) => {
      commit("spotChecking/updateSpotCheckConfirmPackageDealer", {
        ...record,
        type: "confirm",
      });
      router.push("/spotCheckConfirmProgramDealer");
    };
    const handleExport = (record: any) => {
      const params = {
        url: `/claimapi/SpotChecking/excelByPackageCodeId?packageCodeId=${record.id}&programBuId=${state.user.bu.orgid}`,
        method: "get",
      };
      downloadFile(params).catch((res) => {
        if (res.message) {
          message.warning(res.message);
        } else {
          message.warning(res);
        }
      });
    };
    const logModalVisible = ref(false);
    const logData = ref([]);
    const handleLog = (record: any) => {
      fetchDealerLog(record.id).then((res) => {
        logData.value = res;
        logModalVisible.value = true;
      });
    };
    return {
      column,
      data,
      search,
      handleGoTo,
      tableSelection,
      isPagination: false,
      handleExport,
      toThousandsAndTwoDecimal,
      logModalVisible,
      handleLog,
      logData,
    };
  },
  components: {
    FileDoneOutlined,
    FileSearchOutlined,
    TableWrapper,
    LogModal,
  },
});
